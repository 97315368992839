<template>
  <div>
    <v-container fluid>
      <v-col class="col-login">
        <v-card
          class="card-login elevation-24"
          style="padding: 20px; border: 1px; border-radius: 2px"
          fluid
          fill-height
        >
          <div class="text-center">
            <v-row
              justify="center"
              align="center"
              max-height="80"
              max-width="260"
            >
              <v-img
                :src="cassemsLogo"
                max-height="30"
                max-width="200"
                class="img-logo"
              ></v-img>
            </v-row>
            <v-row
              justify="center"
              align="center"
              max-height="40"
              max-width="260"
            >
              <h2 class="grey--text mt-2 font-weight">
                Digite seu Usuário e Senha
              </h2>
            </v-row>
          </div>
          <v-form @submit.prevent="realizarLogin" ref="form">
            <v-col>
              <v-card-text>
                <v-text-field
                  v-model="login"
                  type="text"
                  label="Usuário"
                  prepend-inner-icon="face"
                  placeholder="Usuário"
                  :rules="[(v) => !!v || 'O Campo Usuario é Obrigatório.']"
                  required
                />
                <v-text-field
                  v-model="password"
                  :type="senhaVisivel ? 'text' : 'password'"
                  label="Senha"
                  placeholder="Senha"
                  prepend-inner-icon="lock"
                  :append-icon="senhaVisivel ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="senhaVisivel = !senhaVisivel"
                  :rules="[(v) => !!v || 'O Campo Senha é Obrigatório.']"
                  required
                  color="rgba(63,81,181,1)"
                />
              </v-card-text>
              <v-card-actions class="login-action justify-center align-center">
                <v-btn
                  class="justify-center align-center"
                  :loading="isLoadingData"
                  type="submit"
                  large
                  color="rgba(63,81,181,1)"
                  block
                >
                  <span class="white--text">Entrar</span>
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-form>
        </v-card>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import cassemsLogo from "@/assets/logo_cassems.png";

import AutenticacaoApi from "@/apis/autenticacao/AutenticacaoApi.js";
import { Role } from "@/router/enum/roles";

export default {
  name: "Login",
  data() {
    return {
      login: "",
      password: "",
      isLoadingData: false,
      senhaVisivel: false,
      cassemsLogo,
    };
  },
  methods: {
    realizarLogin() {
      if (this.$refs.form.validate()) {
        const credenciais = { login: this.login, password: this.password };

        this.isLoadingData = true;

        AutenticacaoApi.realizarLogin(credenciais)
          .then((response) => {
            const resposta = response.data;
            console.log(response);
            sessionStorage.setItem("usuario-token", JSON.stringify(resposta));
            
            const roles = JSON.parse(
              Buffer.from(resposta.token.split(".")[1], "base64").toString()
            ).roles;

            for (const role of Object.keys(Role)) {
              sessionStorage.setItem(
                role,
                roles.includes(Role[role]).toString()
              );
            }

            this.$router.push(
              sessionStorage.getItem("caminho-requisitado") || "/"
            );

            sessionStorage.removeItem("caminho-requisitado");

            this.$toast.open({
              position: "top-right",
              message: "Seja Bem-Vindo " + resposta.name,
              type: "info",
              duration: 5000,
            });
          })
          .catch((error) => {
            console.log(error);
            const messagemDeErro =
              typeof error.response.data.statusCode === "undefined"
                ? "Ocorreu um erro ao Realizar o Login."
                : error.response.data.message;

            this.$toast.open({
              position: "top-right",
              message: messagemDeErro,
              type: "error",
              duration: 5000,
            });

            sessionStorage.removeItem("usuario-token");
          })
          .finally(() => {
            this.isLoadingData = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-login {
  min-height: 480px;
  padding: 40px 20px;
}

.img-logo {
  margin: 40px 0;
}

.login-action {
  margin: 40px 0;
}
</style>
